import React, { forwardRef } from 'react';
import styles from './index.module.scss';
import illustrate from '../../assets/img/illustration-success.svg';

interface IProps {
    ref: React.Ref<HTMLDivElement>;
    handleFormSuccess(isFormSuccess: boolean): void;
}

const FormSuccess: React.FC<IProps> = forwardRef(
    ({ handleFormSuccess }, ref) => {
        return (
            <div className={styles.container} ref={ref}>
                <button
                    type="button"
                    className={`button-link ${styles['button-close']}`}
                    onClick={() => {
                        handleFormSuccess(false);
                    }}
                >
                    <i className={styles['icon-close']} />
                    close
                </button>
                <div className={styles.content}>
                    <img src={illustrate} className={styles.illustration} />
                    <div className={styles.heading}>Thanks for applying!</div>
                    <div className={styles.description}>
                        Your fast response secured a&nbsp;more competitive
                        salary in&nbsp;the event you get the job!
                    </div>
                </div>
            </div>
        );
    }
);

export default FormSuccess;
