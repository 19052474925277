const dataCounter = {
    minOffer: 70000.0, // rub;
    maxOffer: 150000.0, // rub;
    week: 604800000, // ms
    day: 86400000, // ms
    start: new Date(2020, 11, 17).getTime(), //ms
    period(): number {
        return this.week * 3; // ms
    },
};

export default dataCounter;
