import React, { useEffect, useState } from 'react';

let hidden: 'hidden';
let visibilityChange: 'visibilitychange';
if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
}

const getIsDocumentHidden = () => {
    return !document[hidden];
};

const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

    useEffect(() => {
        document.addEventListener(visibilityChange, onVisibilityChange, false);
        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange);
        };
    });
    return isVisible;
};

export default usePageVisibility;
