import React from 'react';
import classname from 'classnames';
import styles from './index.module.scss';

const URL = window.location.origin;

const data = [
    {
        name: 'vk',
        url: `http://vk.com/share.php?url=${URL}&title=Freelance web designer wanted&description=Looking for a proactive person to cover a challenging project&image=share2.png&noparse=true`,
    },
    {
        name: 'fb',
        url: `https://www.facebook.com/sharer/sharer.php?u=${URL}`,
    },
    {
        name: 'tw',
        url: `https://twitter.com/share?url=${URL}&text=Freelance web designer wanted`,
    },
];

const Social: React.FC<{ about?: boolean }> = ({ about }) => {
    return (
        <div
            className={classname(styles.container, {
                [styles['container-about']]: about,
            })}
        >
            <div className={styles.label}>Share on&nbsp;social networks:</div>
            <div className={styles.list}>
                {data.map((item) => (
                    <a
                        className={styles.link}
                        key={item.name}
                        href={item.url}
                        title={item.name}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i
                            className={`${styles[`icon-${item.name}`]} ${
                                styles.icon
                            }`}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Social;
