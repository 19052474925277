import React from 'react';
import classname from 'classnames';
import styles from './index.module.scss';
import Counter from '../Counter';

interface IProps {
    scrollPosition: number;
    h1position: number;
    offer: number;
    fadeInText: boolean;
    handleNextPage(isSecondPage: boolean): void;
}

const Header: React.FC<IProps> = ({
    scrollPosition,
    h1position,
    offer,
    handleNextPage,
    fadeInText,
}) => {
    return (
        <div
            className={classname(styles.root, {
                [styles['root-active']]:
                    scrollPosition >= h1position &&
                    scrollPosition !== 0 &&
                    fadeInText,
            })}
        >
            <div className={styles.container}>
                <button
                    type="button"
                    className={`button-link ${styles['button-back']}`}
                    onClick={() => handleNextPage(false)}
                >
                    <i className={styles['icon-back']} />
                    <span>back</span>
                </button>
                <Counter header offer={offer} label={false} />
            </div>
        </div>
    );
};

export default Header;
