import React from 'react';
import classname from 'classnames';
import dataCounter from '../../dataCounter';
import styles from './index.module.scss';

/**
 * numberFormat(value, n, x, s, c)
 *
 * @param value
 * @param n
 * @param x
 * @param s
 * @param c
 */
const numberFormat = function (
    value: number,
    n: number,
    x: number,
    s: string,
    c: string
): string {
    const regexp = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\D' : '$'})`;
    const num = value.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
        new RegExp(regexp, 'g'),
        `$&${s || ','}`
    );
};

interface IProps {
    offer: number;
    label?: boolean;
    about?: boolean;
    header?: boolean;
}

const Counter: React.FC<IProps> = ({ offer, label = true, about, header }) => {
    const renderOffer = () => {
        const { minOffer, maxOffer } = dataCounter;

        if (offer <= minOffer) {
            return numberFormat(minOffer, 2, 3, ' ', ', ');
        }

        if (offer >= maxOffer) {
            return numberFormat(maxOffer, 2, 3, ' ', ', ');
        }

        return numberFormat(offer, 2, 3, ' ', ', ');
    };

    return (
        <div
            className={classname(styles.root, {
                [styles['root-about']]: about,
                [styles['root-header']]: header,
                [styles['root-active']]: offer !== 0,
            })}
        >
            {label && <div className={styles.label}>Your monthly salary</div>}
            <div className={styles.value}>
                {renderOffer()}
                <i className={styles.symbol} />
            </div>
        </div>
    );
};

export default Counter;
