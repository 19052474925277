import React, { useState, useEffect, useRef } from 'react';
import classname from 'classnames';
import Counter from '../../components/Counter';
import styles from './index.module.scss';
import Video from '../../components/Video';
import illustration1 from '../../assets/img/illustration-1.svg';
import illustration2 from '../../assets/img/illustartion-2.svg';
import illustration3 from '../../assets/img/illustartion-3.svg';
import MainForm from '../../components/MainForm';
import Social from '../../components/Social';
import Header from '../../components/Header';

interface IProps {
    offer: number;
    fadeInText: boolean;
    handleNextPage(isSecondPage: boolean): void;
    handleFormSuccess(isFormSuccess: boolean): void;
}

const About: React.FC<IProps> = ({
    offer,
    handleNextPage,
    fadeInText,
    handleFormSuccess,
}) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [h1position, setH1Position] = useState(0);
    const h1Ref = useRef<HTMLHeadingElement>(null);
    const [heightPage, setHeightPage] = useState(0);
    const [toggleMouse, setToggleMouse] = useState(true);

    const toggleFixHeader = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const toggleMouseButton = () => {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
            setToggleMouse(false);
        } else {
            setToggleMouse(true);
        }
    };

    const setHeight = () => {
        setHeightPage(() => window.scrollY + window.innerHeight);
    };

    const handleScroll = () => {
        toggleFixHeader();
        toggleMouseButton();
        setHeight();
    };

    const setScroll = () => {
        window.scrollTo({
            top: heightPage,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (h1Ref.current !== null) {
            const { offsetTop } = h1Ref.current;
            setH1Position(offsetTop);
        }

        handleScroll();

        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setHeight();
        window.addEventListener('resize', setHeight);

        return () => window.removeEventListener('resize', setHeight);
    }, []);

    return (
        <div className={styles.container}>
            <Video />
            <Header
                scrollPosition={scrollPosition}
                h1position={h1position}
                offer={offer}
                handleNextPage={handleNextPage}
                fadeInText={fadeInText}
            />
            <button
                type="button"
                onClick={setScroll}
                className={classname('button-link', styles.mouse, {
                    [styles['mouse-active']]: toggleMouse,
                })}
            >
                <span />
            </button>
            <section
                className={`${styles['section-1']} ${styles['section-info']}`}
            >
                <div
                    className={classname(styles.content, styles['content-1'], {
                        [styles['content-active']]: fadeInText,
                    })}
                >
                    <div className={styles.header}>
                        <button
                            type="button"
                            className={`button-link ${styles['button-back']}`}
                            onClick={() => handleNextPage(false)}
                        >
                            <i className={styles['icon-back']} />
                            back
                        </button>
                    </div>
                    <div className={styles.counter}>
                        {scrollPosition < h1position ? (
                            <Counter offer={offer} label={false} about />
                        ) : null}
                    </div>
                    <h1 className={styles.h1} ref={h1Ref}>
                        <span>Lose more money</span> while reading full
                        description
                    </h1>
                </div>
            </section>
            <section className={styles['section-video']} />
            <section
                className={`${styles['section-2']} ${styles['section-info']}`}
            >
                <div
                    className={classname(styles.content, styles['content-2'], {
                        [styles['content-active']]: fadeInText,
                    })}
                >
                    <div className={styles['paragraph-block']}>
                        <h3 className={styles.h3}>We&nbsp;are looking</h3>
                        <p className={styles.paragraph}>
                            For an&nbsp;ambitious person with proven UX/UI
                            experience to&nbsp;work remotely and lead corporate
                            website redesign. You will be&nbsp;acting
                            in&nbsp;close collaboration with the product
                            marketing team, frontend, backend and external
                            agencies to&nbsp;bring projects and ideas
                            to&nbsp;life.
                        </p>
                    </div>
                    <div className={styles['illustration-1']}>
                        <img src={illustration1} />
                    </div>
                    <div className={styles['paragraph-block']}>
                        <h3 className={styles.h3}>Your challenge</h3>
                        <p className={styles.paragraph}>
                            Will be&nbsp;translating business objectives into
                            visual concepts and implement them as&nbsp;design
                            systems and toolkits. You may also work
                            on&nbsp;campaign key visuals, assets and&nbsp;UX
                            design projects with some contribution to&nbsp;UI
                            design too. You should be&nbsp;motivated and
                            productive with a&nbsp;passion for creative and
                            user-centered outcomes. Be&nbsp;ready to&nbsp;stand
                            up&nbsp;for your ideas, ask questions and offer
                            solutions. This will require great communication
                            skills and you must be&nbsp;fluent in&nbsp;English.
                        </p>

                        <p
                            className={`${styles.paragraph} ${styles['paragraph-last']}`}
                        >
                            Please be&nbsp;ready for the test task.
                        </p>
                    </div>
                </div>
            </section>
            <section className={styles['section-video']} />
            <section
                className={`${styles['section-3']} ${styles['section-info']}`}
            >
                <div
                    className={classname(styles.content, styles['content-3'], {
                        [styles['content-active']]: fadeInText,
                    })}
                >
                    <h2 className={styles.h2}>Requirements</h2>
                    <h2
                        className={`${styles.h2} ${styles['h2-shadow']} ${styles['h2-shadow-requirements']}`}
                    >
                        Requirements
                    </h2>
                    <ul className={styles['requirements-list']}>
                        <li className={styles['requirements-item']}>
                            Figma and Adobe tools
                        </li>
                        <li className={styles['requirements-item']}>
                            Strong understanding of&nbsp;usability and
                            user-centered design practices
                        </li>
                        <li className={styles['requirements-item']}>
                            Excellent verbal communication and collaboration
                            skills
                        </li>
                        <li className={styles['requirements-item']}>
                            A strong portfolio demonstrating web design and UI
                            best practices
                        </li>
                        <li className={styles['requirements-item']}>
                            You will need to&nbsp;be&nbsp;able to&nbsp;draw
                            isometric illustrations
                        </li>
                        <li className={styles['requirements-item']}>
                            Applicants from Russia only (Russia working permit
                            required)
                        </li>
                    </ul>
                </div>
            </section>
            <section className={styles['section-video']} />
            <section
                className={`${styles['section-4']} ${styles['section-info']}`}
            >
                <div
                    className={classname(styles.content, styles['content-4'], {
                        [styles['content-active']]: fadeInText,
                    })}
                >
                    <h2 className={`${styles.h2} ${styles['h2-offer']}`}>
                        We
                        <br />
                        <span>Offer</span>
                    </h2>
                    <h2
                        className={`${styles.h2} ${styles['h2-shadow']} ${styles['h2-shadow-offer']}`}
                    >
                        We
                        <br />
                        <span>Offer</span>
                    </h2>
                    <ul className={styles['offer-list']}>
                        <li className={styles['offer-item']}>
                            <i className={styles['offer-icon']} />
                            Modern, energetic multicultural environment
                        </li>
                        <li className={styles['offer-item']}>
                            <i className={styles['offer-icon']} />
                            Interesting people, an&nbsp;excellent team
                            of&nbsp;professionals
                        </li>
                        <li className={styles['offer-item']}>
                            <i className={styles['offer-icon']} />
                            Extensive opportunities for professional growth and
                            career
                        </li>
                        <li className={styles['offer-item']}>
                            <i className={styles['offer-icon']} />
                            Annual leave of 28 days
                        </li>
                    </ul>
                </div>
            </section>
            <section className={styles['section-video']} />
            <section
                className={`${styles['section-5']} ${styles['section-info']}`}
            >
                <div
                    className={classname(styles.content, styles['content-5'], {
                        [styles['content-active']]: fadeInText,
                    })}
                >
                    <div className={styles.form}>
                        <MainForm
                            about
                            offer={offer}
                            handleFormSuccess={handleFormSuccess}
                        />
                    </div>
                    <div className={styles['illustration-2']}>
                        <img src={illustration2} />
                    </div>
                    <div className={styles['illustration-3']}>
                        <img src={illustration3} />
                    </div>
                    <div className={styles.social}>
                        <Social about />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;
