import React, { useEffect, useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { CSSTransition } from 'react-transition-group';
import dataCounter from '../dataCounter';
import styles from './index.module.scss';
import Video from '../components/Video';
import Main from '../pages/Main';
import About from '../pages/About';
import FormSuccess from '../components/FormSuccess';

const scrollToTopAnimation = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTopAnimation);
        window.scrollTo(0, c - c / 4);
    }
};

function scrollToTop(callback: () => void) {
    const onScroll = function () {
        if (window.scrollY === 0) {
            window.removeEventListener('scroll', onScroll);
            callback();
        }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    scrollToTopAnimation();
}

function App() {
    const [offer, setOffer] = useState<number>(0);
    const [timer, setTimer] = useState<number | undefined>(undefined);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [secondPage, setSecondPage] = useState<boolean>(false);
    const [fadeAboutText, setFadeAboutText] = useState(false);
    const [slideVideo, setSlideVideo] = useState(false);
    const [mainVideoRender, setMainVideoRender] = useState(true);
    const [mountAbout, setMountAbout] = useState(false);
    const formSuccessRef = useRef<HTMLDivElement>(null);
    const pageRef = useRef(null);

    useEffect(() => {
        const { minOffer, maxOffer, start } = dataCounter;
        let newStart = start;
        const period = dataCounter.period();

        while (newStart + period <= Date.now()) {
            newStart += period;
        }

        const intervalId = window.setInterval(() => {
            const passedTime = Date.now() - newStart;
            const ratio = passedTime / period;
            const moneyLeft = (maxOffer - minOffer) * (1 - ratio) + minOffer;

            setOffer(moneyLeft);
        }, 250);

        setTimer(intervalId);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleFormSuccess = (isFormSuccess: boolean) => {
        setFormSuccess(isFormSuccess);

        /* if (isFormSuccess) {
            setTimeout(() => {
                setFormSuccess(false);
            }, 3000);
        } */
    };

    const handleNextPage = (isSecondPage: boolean) => {
        if (window.scrollY !== 0) {
            scrollToTop(() => {
                setSecondPage(isSecondPage);
            });
        } else {
            setSecondPage(isSecondPage);
        }
    };

    const handleFadeAboutText = () => {
        setFadeAboutText((prevState) => !prevState);
    };

    const handleAboutPage = () => {
        setMountAbout((prevState) => !prevState);
    };

    const handleSlideVideo = () => {
        setSlideVideo((prevState) => !prevState);
    };

    return (
        <div className={styles.root}>
            <CSSTransition
                nodeRef={formSuccessRef}
                timeout={400}
                classNames="form-success"
                unmountOnExit
                in={formSuccess}
            >
                <FormSuccess
                    ref={formSuccessRef}
                    handleFormSuccess={handleFormSuccess}
                />
            </CSSTransition>
            <Video
                isSlideVideo={slideVideo}
                main
                isVideoRender={mainVideoRender}
            />
            <CSSTransition
                nodeRef={pageRef}
                timeout={800}
                classNames="slide"
                in={!secondPage}
                unmountOnExit
                onExit={() => {
                    handleAboutPage();
                    handleSlideVideo();
                }}
                onExited={() => {
                    setMainVideoRender(false);
                    handleFadeAboutText();
                }}
                onEnter={() => {
                    setMainVideoRender(true);
                    handleSlideVideo();
                }}
                onEntered={() => {
                    handleFadeAboutText();
                    handleAboutPage();
                }}
            >
                <Main
                    ref={pageRef}
                    offer={offer}
                    handleFormSuccess={handleFormSuccess}
                    handleNextPage={handleNextPage}
                />
            </CSSTransition>
            {mountAbout ? (
                <About
                    offer={offer}
                    handleNextPage={handleNextPage}
                    handleFormSuccess={handleFormSuccess}
                    fadeInText={fadeAboutText}
                />
            ) : null}
        </div>
    );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
