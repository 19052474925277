import React, { forwardRef } from 'react';
import Counter from '../../components/Counter';
import styles from './index.module.scss';
import MainForm from '../../components/MainForm';
import Social from '../../components/Social';

interface IProps {
    ref: React.Ref<HTMLDivElement>;
    offer: number;
    handleFormSuccess(isFormSuccess: boolean): void;
    handleNextPage(isSecondPage: boolean): void;
}

const Main: React.FC<IProps> = forwardRef(
    ({ offer, handleFormSuccess, handleNextPage }, ref) => {
        return (
            <div className={styles.container} ref={ref}>
                <div className={styles.line} />
                <div className={styles['column-first']}>
                    <section className={styles.counter}>
                        <Counter offer={offer} />
                    </section>
                    <section className={styles.header}>
                        <h1 className={styles.h1}>
                            Watch your money go&nbsp;&mdash;{' '}
                            <span>or&nbsp;apply now!</span>
                        </h1>
                        <div className={styles['header-description']}>
                            We&nbsp;need a&nbsp;freelance web designer
                            to&nbsp;cover a&nbsp;long-lasting and challenging
                            project with a&nbsp;large international&nbsp;IT
                            company.
                        </div>
                    </section>
                </div>
                <div className={styles['column-second']}>
                    <section className={styles.apply}>
                        <div className={styles['apply-description']}>
                            Thank you everyone for your interest in&nbsp;this
                            job! Though this website remains in&nbsp;place for
                            the history, the counter has stopped, and we are now
                            reviewing your responses. However, you are still
                            welcome to share your portfolios and we may contact
                            you once we have other challenging projects!
                        </div>
                    </section>
                    <section className={styles.form}>
                        <MainForm
                            offer={offer}
                            handleFormSuccess={handleFormSuccess}
                        />
                    </section>
                    <section className={styles.about}>
                        <button
                            type="button"
                            className={`button-link ${styles['about-link']}`}
                            onClick={() => handleNextPage(true)}
                        >
                            Lose more money while reading full description
                            <i className={styles['about-icon']} />
                        </button>
                    </section>
                    <section className={styles.social}>
                        <Social />
                    </section>
                </div>
            </div>
        );
    }
);

export default Main;
