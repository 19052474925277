import React from 'react';
import classname from 'classnames';
import validator from 'validator';
import { FormikHelpers, useFormik } from 'formik';
import dataCounter from '../../dataCounter';
import styles from './index.module.scss';

interface IFormErrors {
    link: string;
    agreement: string;
}

interface IData {
    link: string;
    offer: string;
    agreement: boolean;
    api: string;
}

interface IProps {
    offer: number;
    about?: boolean;
    handleFormSuccess(isFormSuccess: boolean): void;
}

const validate = (values: IData) => {
    const errors: IFormErrors = {} as IFormErrors;

    if (!validator.isURL(values.link)) {
        errors.link = 'Please enter a valid link';
    }

    if (!values.agreement) {
        errors.agreement =
            'Please confirm your agreement with the terms and conditions of the privacy policy';
    }

    return errors;
};

const MainForm: React.FC<IProps> = ({ offer, handleFormSuccess, about }) => {
    const data: IData = {
        link: '',
        offer: '',
        agreement: true,
        api: '',
    };

    const getValueOffer = () => {
        const { minOffer, maxOffer } = dataCounter;

        if (offer <= minOffer) {
            return minOffer.toFixed(2);
        }

        if (offer >= maxOffer) {
            return maxOffer.toFixed(2);
        }

        return offer.toFixed(2);
    };

    const handleSubmit = async (
        values: IData,
        { setErrors, resetForm, setSubmitting }: FormikHelpers<IData>
    ) => {
        values.offer = getValueOffer();
        delete values.api;

        await fetch('/api', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    setErrors({ api: 'Please try again later' });
                    throw new Error('Something went wrong.');
                }
                resetForm();

                return response;
            })
            .then((response: Response) => {
                handleFormSuccess(true);
                console.log('Request successful', response);
            })
            .catch((error: Error) => {
                console.log('Request failed', error);
                setErrors({ api: 'Please try again later' });
            });
    };

    const formik = useFormik({
        initialValues: data,
        validate,
        onSubmit: handleSubmit,
    });

    return (
        <form
            onSubmit={formik.handleSubmit}
            className={classname(styles.root, {
                [styles['root-about']]: about,
            })}
        >
            <div className={styles.name}>Add a link to your portfolio</div>
            <div className={styles.block}>
                <i className={styles['input-icon']} />
                <input
                    type="text"
                    name="link"
                    placeholder="Put a link here"
                    className={classname(styles.input, {
                        [styles['input-error']]: formik.errors.link,
                    })}
                    onChange={formik.handleChange}
                    value={formik.values.link}
                />
                {formik.errors.link ? (
                    <div className={styles['input-info']}>
                        {formik.errors.link}
                    </div>
                ) : null}
            </div>

            <div className={styles.block}>
                <label className={styles.checkbox}>
                    <input
                        className={styles['checkbox-input']}
                        checked={formik.values.agreement}
                        type="checkbox"
                        name="agreement"
                        onChange={formik.handleChange}
                    />
                    <div className={styles['checkbox-label']}>
                        <span>
                            I agree to the terms and{' '}
                            <a target="_blank" href="./user_agreement.pdf">
                                conditions of the privacy policy
                            </a>
                        </span>
                    </div>
                </label>
                {formik.errors.agreement ? (
                    <div className={styles['input-info']}>
                        {formik.errors.agreement}
                    </div>
                ) : null}
            </div>

            <div className={styles.block}>
                <button
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                    className={styles.button}
                >
                    Send Link
                </button>
                {formik.errors.api ? (
                    <div
                        className={`${styles['input-info']} ${styles['input-info-api']}`}
                    >
                        {formik.errors.api}
                    </div>
                ) : null}
            </div>
        </form>
    );
};

export default MainForm;
