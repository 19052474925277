import React, { useEffect, useRef, useState } from 'react';
import classname from 'classnames';
import usePageVisibility from './usePageVisibility';
import styles from './index.module.scss';
import videoMp4 from '../../assets/video/video.mp4';
import videoMp4Vertical from '../../assets/video/video-vertical.mp4';
import posterJpg from '../../assets/img/poster.jpg';
import posterVerticalJpg from '../../assets/img/poster-vertical.jpg';

interface IProps {
    isSlideVideo?: boolean;
    main?: boolean;
    isVideoRender?: boolean;
}

function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
    let timeoutID: number;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutID);
        timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
    } as F;
}

const Video: React.FC<IProps> = ({ isSlideVideo, main, isVideoRender }) => {
    const isVisible = usePageVisibility();
    const videoSrc = useRef<HTMLVideoElement | null>(null);
    const [poster, setPoster] = useState(posterJpg);

    const renderVideoSrc = () => {
        if (!videoSrc.current) {
            return null;
        }

        const { src } = videoSrc.current;

        if (window.matchMedia('(orientation: portrait)').matches) {
            if (src !== videoMp4Vertical) {
                videoSrc.current.src = videoMp4Vertical;
                setPoster(posterVerticalJpg);
            }
        } else {
            if (src !== videoMp4) {
                videoSrc.current.src = videoMp4;
                setPoster(posterJpg);
            }
        }
    };

    useEffect(() => {
        if (!videoSrc.current) {
            return;
        }

        if (isVisible && videoSrc.current.paused) {
            const playPromise = videoSrc.current.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                    .catch(() => {
                        // Auto-play was prevented
                        // Show paused UI.
                    });
            }
        } else {
            videoSrc.current.pause();
        }
    }, [isVisible]);

    useEffect(() => {
        const debouncedHandleResize = debounce(renderVideoSrc, 500);

        let windowWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            if (window.innerWidth != windowWidth) {
                windowWidth = window.innerWidth;
                debouncedHandleResize();
            }
        });

        renderVideoSrc();

        return () =>
            window.removeEventListener('resize', debouncedHandleResize);
    }, []);

    return (
        <div
            className={classname(styles.root, {
                [styles['root-active']]: isSlideVideo,
                [styles['root-main']]: main,
            })}
        >
            {isVideoRender || !main ? (
                <video
                    ref={videoSrc}
                    poster={poster}
                    autoPlay
                    loop
                    muted
                    playsInline
                    width="100%"
                    height="100%"
                    className={styles.video}
                >
                    <source src={videoMp4} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
            ) : null}
        </div>
    );
};

export default Video;
